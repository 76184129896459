const toastr = require("toastr");
const Quill = require("quill");

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut"
};

import { Elm } from "../Main";

const domain = document.getElementById("domain").value;
const notice = document.getElementById("devise-notice").textContent;
const error = document.getElementById("devise-error").textContent;

const target = document.getElementById("elm");
const locale = document.getElementById("locale").value;

const storageKey = "sakecom";
const storage = localStorage.getItem(storageKey);

document.body.appendChild(target);
const app = Elm.Main.init({
  node: target,
  flags: {
    domain: domain,
    locale: locale,
    storage: JSON.parse(storage)
  }
});

app.ports.storeSession.subscribe(function(val) {
  if (val === null) {
    localStorage.removeItem(storageKey);
  } else {
    localStorage.setItem(storageKey, JSON.stringify(val));
  }

  setTimeout(function() {
    app.ports.onSessionChange.send(val);
  }, 0);
});

window.addEventListener(
  "storage",
  function(event) {
    if (event.storageArea === localStorage && event.key === storageKey) {
      app.ports.onSessionChange.send(event.newValue);
    }
  },
  false
);

app.ports.metaDescription.subscribe(description => {
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", description);
});

app.ports.createEditor.subscribe(config => {
  var options = {
    modules: {
      toolbar: "#toolbar"
    },
    theme: "snow"
  };
  setupEditor(config, 0, options);
});

app.ports.createViewer.subscribe(config => {
  var options = {
    modules: {
      toolbar: false
    },
    readOnly: true,
    theme: "snow"
  };
  setupEditor(config, 0, options);
});

var quill = null;
function setupEditor(config, times, options) {
  if (times >= 10) {
    return;
  }
  const div = document.getElementById(config.id);
  if (div === null) {
    setTimeout(() => setupEditor(config, times + 1, options), 500);
    return;
  }
  const toolbar = document.getElementById("toolbar");
  if (toolbar !== null && toolbar.classList.contains("ql-toolbar")) {
    // クラスを含んでいるかどうかで、読み込み済みかを判定する
    // もし読み込み済みならオプションは再読込しない
    options = null;
  }
  quill = new Quill("#" + config.id, options);
  const delta = JSON.parse(config.delta);
  quill.setContents(delta, "silent");
}

app.ports.beforeSubmit.subscribe(() => {
  const contents = {
    text: quill.getText(),
    delta: JSON.stringify(quill.getContents().ops)
  };
  app.ports.blogContents.send(contents);
});

app.ports.tracking.subscribe(url => {
  ga("set", "page", url);
  ga("send", "pageview");
});

app.ports.notify.subscribe(message => {
  toastr.info(message);
});

app.ports.error.subscribe(message => {
  toastr.error(message);
});

app.ports.googleMap.subscribe(p => {
  if (p.latitude > 1) {
    createMap(p.latitude, p.longitude, 0);
    return;
  }
  geocode(p.address, 0);
});

var map;
var marker;
function createMap(latitude, longitude, times) {
  if (times >= 10) {
    return;
  }
  const mapDiv = document.getElementById("google-map");
  if (mapDiv === null) {
    setTimeout(() => createMap(latitude, longitude, times + 1), 1000);
    return;
  }
  const location = {
    lat: latitude,
    lng: longitude
  };
  map = new google.maps.Map(mapDiv, {
    center: location,
    zoom: 19
  });
  marker = new google.maps.Marker({
    position: location,
    map: map
  });
}

function geocode(address, times) {
  if (times >= 10) {
    return;
  }
  try {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        address: address,
        region: "JP"
      },
      function(results, status) {
        if (status !== "OK" && !results[0]) {
          return;
        }
        createMap(
          results[0].geometry.location.lat(),
          results[0].geometry.location.lng(),
          0
        );
      }
    );
  } catch (e) {
    if (e instanceof ReferenceError) {
      setTimeout(() => geocode(address, times + 1), 1000);
    }
  }
}

if (error.length > 0) {
  toastr.error(error);
}

if (notice.length > 0) {
  toastr.info(notice);
}

const setupGoogleAds = () => {
  setTimeout(() => {
    try {
      (adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      // do nothing
    }
  }, 2000);
};

app.ports.setupAds.subscribe(() => {
  setupGoogleAds();
});

setupGoogleAds();
